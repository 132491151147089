import React, { Component } from 'react';
import { isEmpty, isEqual, reduce } from 'lodash';

import { Button } from '@mui/material';

import AssessmentQuestion from "./AssessmentQuestion";
import { withUser } from "../../context/UserContext";
import "./Assessment.css";
import AssessmentHeader from "./AssessmentHeader";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";

import ModalSlider from '../ModalSlider/ModalSlider';
class Assessment extends Component {

	constructor(props) {
		super(props);
		this.state = {
			assessment: props.assessment,
			groups: [],
			formErrors: [],
			readOnly: false,
			validations: new Map(),
			validAssessment: true,
			answers: new Map(),
			newAssessment: false,
			questionModalView: false,
			activeQuestionModal: false,
			activeGroup: null
		}
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleAnswer = this.handleAnswer.bind(this);
		this.changeQuestionViewHandler = this.changeQuestionViewHandler.bind(this);
		this.activeQuestionModalHandler = this.activeQuestionModalHandler.bind(this);
		this.nextActiveGroup = this.nextActiveGroup.bind(this);
		this.previousActiveGroup = this.previousActiveGroup.bind(this);

	}

	componentDidMount() {
		if (this.state.assessment) {
			const groups = this.state.assessment.template.groups
				.map((group) => {
					group.open = true;
					return null;
				});
			this.setState({ groups: groups });
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.assessment !== this.state.assessment) {
			this.setState({ assessment: this.props.assessment })
			if (this.props.assessment) {

			}
		}
	}

	changeQuestionViewHandler() {
		this.setState({ questionModalView: !this.state.questionModalView })
	}

	activeQuestionModalHandler(index) {
		this.setState({
			activeQuestionModal: !this.state.activeQuestionModal,
			activeGroup: index
		})
	}

	previousActiveGroup() {
		this.setState({
			activeGroup: this.state.activeGroup - 1
		})
	}

	nextActiveGroup() {
		this.setState({
			activeGroup: this.state.activeGroup + 1
		})
	}

	answeredQuestions() {
		const answers = [];
		this.state.answers.forEach((element) => answers.push(element));
		return reduce(answers, (result, answerList) => {
			if (!isEmpty(answerList)) ++result;
			return result;
		}, 0);
	}

	render() {
		const groups = this.state.assessment && this.state.assessment.id > 0 ? this.state.assessment.template.groups : [];
		return (
			<div className="assessment">
				{this.state.formErrors.length > 0 &&
					<div className="form-errors alert alert-danger">
						<ul>
							{this.state.formErrors.map((error) => <li>{error}</li>)}
						</ul>
					</div>
				}


				{this.state.assessment &&
					<>
						<AssessmentHeader assessment={this.state.assessment}
							handleSubmit={this.handleSubmit}
							readOnly={this.state.readOnly}
							validationErrors={!this.state.validAssessment}
							onClose={this.props.handleClose}
							changeQuestionView={this.changeQuestionViewHandler} />
						<div className={"assessment-contents"}>
							<div className={"groups"}>
								{groups.map((group, index) => {
									return (
										<div className="group" key={index}>
											<div className="row group-control">
												<div className="col-md-3">{group.name}</div>
												<div className="col-md-8 instructions">{group.instructions && group.instructions.trim().length > 0 ? group.instructions : this.state.assessment.template.instructions}</div>
												<div className="col-md-1 icon text-right">
													{group.open && !this.state.questionModalView &&
														<CaretDownOutlined onClick={() => this.toggleGroup(index)} />
													}
													{!group.open && !this.state.questionModalView &&
														<CaretUpOutlined onClick={() => this.toggleGroup(index)} />
													}
													{this.state.questionModalView &&
														<Button onClick={() => this.activeQuestionModalHandler(index)}>View</Button>
													}
												</div>
											</div>
											{group.open && !this.state.questionModalView ?
												<div className={"questions"}>
													{group.questions.map((question, index) => (
														this.shouldShow(question) ?
															<AssessmentQuestion key={index}
																index={index}
																question={question}
																assessmentId={this.state.assessment.id}
																readOnly={this.state.readOnly}
																submitted={this.state.submitted}
																onAnswer={this.handleAnswer}
																valid={!this.state.submitted || (this.state.submitted && this.state.validations.get(question.id))} /> :
															null
													))}
												</div> : null}
										</div>)
								})}
							</div>
						</div>
					</>
				}
				{this.state.activeQuestionModal && (
					<ModalSlider
						activeGroup={groups[this.state.activeGroup]}
						assessmentId={this.state.assessment.id}
						firstGroup={isEqual(this.state.activeGroup, 0)}
						lastGroup={isEqual(this.state.activeGroup + 1, groups.length)}
						nextActiveGroup={this.nextActiveGroup}
						previousActiveGroup={this.previousActiveGroup}
						onAnswer={this.handleAnswer}
						readOnly={this.state.readOnly}
						completedQuestions={`${this.answeredQuestions()}/${this.state.answers.size}`}
						remainingQuestions={this.state.answers.size - this.answeredQuestions()}
						submitted={this.state.submitted}
						validations={this.state.validations}
						handleClose={() => this.activeQuestionModalHandler(null)} />
				)}
			</div>
		)
	}

	shouldShow(question) {
		if (this.state.assessment === null || question.dependencies.length === 0)
			return true;
		let show = true;
		const dependencies = question.dependencies.sort((a, b) => (a.order > b.order) ? 1 : -1)
		dependencies.forEach((dependency) => {

			//TODO: Implement inter dependencies (AND, OR, etc.)
			const dependentQuestionId = dependency.assessmentTemplateDependentQuestionId;
			const answers = this.state.answers.get(dependentQuestionId)
			switch (dependency.operator) {
				case "EQUALS": {
					const dependentQuestion = this.findQuestion(dependentQuestionId)
					switch (dependentQuestion.questionType) {
						case "FREE_TEXT":
							if (answers === undefined || answers.length === 0 || !answers[0].answerText || answers[0].answerText.length === 0)
								show = false;
							break;
						case "SELECTION":
							show = false;
							if (answers !== undefined && answers.length > 0) {
								answers.forEach((answer) => {
									if (answer.assessmentTemplateAnswerId === dependency.assessmentTemplateAnswerId)
										show = true;
								});
							}
							break;
						case "MULTIPLE_SELECTION":
							//TODO: Implement
							break;
						case "RANGE":
						case "NUMERIC_VALUE":
							if (answers === undefined || answers.length === 0 ||
								!answers[0].answerNumeric || answers[0].answerNumeric !== dependency.answerNumeric)
								show = false;
							break;
						case "DATE":
							//TODO: Implement
							break;
						default:
					}
					break;
				}
				//TODO: Implement the rest
				case "GREATER":
					break;
				case "LESS":
					break;
				case "GREATER_OR_EQUAL":
					break;
				case "LESS_OR_EQUAL":
					break;
				case "NOT":
					break;
				case "CONTAINS":
					break;
				default:
			}
		});
		return show
	}

	findQuestion(id) {
		const questions = [];
		const groups = this.state.assessment.template.groups;
		groups.map((group) => questions.push(...group.questions))
		let question = questions.find((question) => question.id === id);
		if (question === undefined)
			question = null;
		return question;
	}

	toggleGroup(index) {
		let assessment = this.state.assessment;
		if (assessment.id > 0) {
			let template = assessment.template;
			let groups = template.groups;
			let group = groups[index];
			group.open = !group.open;
			groups[index] = group;
			template.groups = groups;
			assessment.template = template;
			this.setState({ assessment: assessment });
		}

	}

	handleAnswer(question, answers) {

		const valid = this.state.validations;
		valid.set(question.id, (!question.required || (question.required && (answers && answers.length > 0))));
		const ans = this.state.answers;
		ans.set(question.id, answers)
		this.setState({ validations: valid, answers: ans })
	}

	isValidForm() {
		if (this.state.validations.size === 0) {
			this.setState({ validAssessment: false });
			return false;
		}
		let valid = true;
		for (const [, value] of this.state.validations.entries()) {
			if (!value) {
				valid = false;
				break;
			}
		}
		this.setState({ validAssessment: valid })
		return valid;
	}

	handleSubmit(event, newAssessment) {
		event.preventDefault();
		this.setState({ submitted: true })
		if (this.isValidForm()) {
			const assessment = this.state.assessment;
			assessment.dateEnded = new Date();
			this.setState({
				assessment: assessment
			})
			this.props.handleClose(true, assessment);
		}
	}
}

export default withUser(Assessment);