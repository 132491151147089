import React, {Component} from 'react';

class NumericValue extends Component {

    constructor(props) {
        super(props);
        this.state = {
            min: props.question.min,
            max: props.question.max,
            steps: props.question.steps,
            answer: (props.answers && props.answers.length > 0) ? props.answers[0] : {answerNumeric: props.question.min},
            readOnly: props.readOnly,
            value: (props.answers && props.answers.length > 0) ? props.answers[0].answerNumeric : props.question.min
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(JSON.stringify(prevProps.answers) !== JSON.stringify(this.props.answers) && this.props.answers.length > 0)
            this.setState({answer: this.props.answers[0]})
        if(prevProps.readOnly !== this.props.readOnly)
            this.setState({readOnly: this.props.readOnly})
    }

    handleChange(event) {
        if(!this.state.readOnly) {
            if(!isNaN(event.target.value)) {
                const answer = {
                    questionId: this.props.question.id,
                    answerText: null,
                    assessmentId: 0,
                    answerNumeric: parseInt(event.target.value),
                    answerDate: null,
                    templateAnswer: null
                }

                this.setState({
                                  answer: answer,
                                  value: event.target.value
                              })

                if(this.props.handleNewAnswer)
                    this.props.handleNewAnswer([answer])
            }
        }
        else {
            this.setState({value: this.state.answer.answerNumeric})
        }
    }

    render() {
        return (
                <div>
                    <input
                            className="form-control"
                            type="number"
                            min={this.state.min}
                            max={this.state.max}
                            required={this.props.question.required}
                            defaultValue={this.state.value}
                            step={this.state.steps}
                            onChange={this.handleChange}
                    />
                </div>
        )
    }
}

export default NumericValue;
